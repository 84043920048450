import { useEffect, useState } from 'react';
import { Typography, Button } from '@mui/material';
import { useQuery } from '@apollo/client';
import { useExcel } from '@hooks';
import { WORKBOOK_CUSTOM_PROPERTIES } from '@utils/excel/shared/constants';
import { GET_WORKBOOK_VERSION_INFO } from 'src/gql/queries/excel';
import { useCallbackOnWindowFocus } from 'src/hooks/useCallbackOnWindowFocus';
import { FlexRow, StyledButton, StyledContainer } from './TemplateVersionWarningBanner.styled';
import { WorkbookRefreshModals } from '../WorkbookRefreshModals/WorkbookRefreshModals';

export function TemplateVersionWarningBanner({ tourId, offerId }:{ tourId: string, offerId: string }) {
  const [isRefreshModalOpen, setRefreshModalOpen] = useState(false);
  const { data: workbookInfo, refetch } = useQuery(GET_WORKBOOK_VERSION_INFO, {
    variables: {
      tourId,
      offerId,
    },
  });
  const templateVersion = workbookInfo?.getVersionInformation?.templateVersion;
  const { getCustomProperties, setCustomProperty } = useExcel();
  const [refreshDismissed, setRefreshDismissed] = useState<string | null>(null);
  const currentVersionDismissed = refreshDismissed === templateVersion;

  useCallbackOnWindowFocus(refetch);

  useEffect(() => {
    const loadCustomProperties = async () => {
      const customProperties = await getCustomProperties();
      setRefreshDismissed(
        customProperties[WORKBOOK_CUSTOM_PROPERTIES.REFRESH_DISMISSED] as string ?? '',
      );
    };

    void loadCustomProperties();
  }, []);

  const handleDismiss = () => {
    if (!templateVersion) return;
    void setCustomProperty(
      WORKBOOK_CUSTOM_PROPERTIES.REFRESH_DISMISSED,
      templateVersion,
    );
    setRefreshDismissed(templateVersion);
  };

  if (
    !workbookInfo?.getVersionInformation
    || workbookInfo?.getVersionInformation?.isCurrent
    || refreshDismissed === null
    || currentVersionDismissed
  ) return null;

  return (
    <>
      {
        isRefreshModalOpen && (
          <WorkbookRefreshModals setOpen={setRefreshModalOpen} onClose={handleDismiss} />
        )
      }
      <StyledContainer>
        <Typography data-testid="workbook-refresh-warning-text">
          This Offer is using an old model template version. Would you like to update to the latest version?
        </Typography>
        <FlexRow>
          <StyledButton
            color="secondary"
            variant="contained"
            data-testid="workbook-refresh-update-button"
            onClick={() => setRefreshModalOpen(true)}
          >
            Update to the Latest Version
          </StyledButton>
          <Button variant="text" onClick={handleDismiss} data-testid="workbook-refresh-dismiss-button">
            Dismiss
          </Button>
        </FlexRow>
      </StyledContainer>
    </>
  );
}
