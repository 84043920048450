/* eslint-disable max-lines-per-function */
import { useAegModeling } from '@hooks';
import React, { SetStateAction, useEffect, useState } from 'react';
import { FormStylingMode, GenericModal } from 'src/components/shared';
import { Alert, Button } from '@mui/material';
import {
  AlertContainer,
  ButtonContainer,
  ConfirmationModalContainer,
  ListContainer,
  ListItemStyled,
} from './WorkbookRefreshModals.styled';

export const WorkbookRefreshModals = ({
  setOpen,
  onClose,
}: {
  setOpen: React.Dispatch<SetStateAction<boolean>>,
  onClose?: () => void
}) => {
  const { canRefreshWorkbook, refreshWorkbook, syncModelingWorkbook } = useAegModeling();
  const [canRefresh, setCanRefresh] = useState<boolean>();

  useEffect(() => {
    const checkCanRefresh = async () => {
      setCanRefresh(await canRefreshWorkbook());
    };

    void checkCanRefresh();
  }, []);

  const handleRefreshWorkbook = async () => {
    await syncModelingWorkbook();
    await refreshWorkbook();
    setOpen(false);
  };

  if (canRefresh === undefined) {
    return null;
  }

  return canRefresh ? (
    <GenericModal open mode={FormStylingMode.UNSET} title="Please confirm you want to update your offer">
      <ConfirmationModalContainer>
        <ListContainer>
          <ListItemStyled data-testid="list-item">
            We will save a copy of the previous offer version for future reference.
          </ListItemStyled>
          <ListItemStyled data-testid="list-item">
            This may impact certain financials (e.g., PRS or insurance rates).
          </ListItemStyled>
        </ListContainer>
        <AlertContainer>
          <Alert data-testid="workbook-close-warning" severity="warning">
            After clicking update, this Excel workbook will automatically close
          </Alert>
        </AlertContainer>
        <ButtonContainer>
          <Button
            data-testid="update-workbook-button"
            variant="contained"
            onClick={() => {
              void handleRefreshWorkbook();
            }}
          >
            Update
          </Button>
          <Button data-testid="dismiss-modal-button" variant="outlined" onClick={() => {
            onClose?.();
            setOpen(false);
          }}>
            Ask Me Later
          </Button>
        </ButtonContainer>
      </ConfirmationModalContainer>
    </GenericModal>
  ) : (
    <GenericModal open mode={FormStylingMode.UNSET} title="Please ask your team to close this file">
      <ListContainer>
        <ListItemStyled data-testid="list-item">
          All users need to close this workbook in order for you to initiate the refresh
        </ListItemStyled>
        <ListItemStyled data-testid="list-item">
          You can see who else has the file open in the top right of Excel
        </ListItemStyled>
        <ListItemStyled data-testid="list-item">
          Once other users have closed the file, initiate the update again
        </ListItemStyled>
      </ListContainer>
      <ButtonContainer>
        <Button data-testid="dismiss-modal-button" onClick={() => setOpen(false)}>
          Got it
        </Button>
      </ButtonContainer>
    </GenericModal>
  );
};
