import { Box, styled } from '@mui/material';

export const DetailContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 4),
}));

export const SearchEventsLoadingContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

export const SwitchContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(0),
  top: theme.spacing(-5),
  paddingRight: theme?.spacing(1),
}));

export const SwitchText = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(1.75),
  [theme.breakpoints.down('smAddIn')]: {
    display: 'none',
  },
}));

export const NonPrimaryToggleContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  left: theme.spacing(2),
}));
